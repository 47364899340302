.profile-form {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  background-color: var(--bg-medium);
  border-radius: 8px;
}

.form-section {
  margin-bottom: 2rem;
}

.form-section h3 {
  color: var(--text-light);
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid var(--bg-light);
}

.form-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-group label {
  color: var(--text-light);
  font-size: 0.9rem;
}

.form-group input,
.form-group select {
  padding: 0.75rem;
  border: 1px solid var(--bg-light);
  border-radius: 4px;
  background-color: var(--bg-dark);
  color: var(--text-light);
  font-size: 1rem;
}

.form-group input:focus,
.form-group select:focus {
  outline: none;
  border-color: var(--accent-color);
}

.submit-button {
  width: 100%;
  padding: 1rem;
  background-color: var(--accent-color);
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.submit-button:hover {
  background-color: #5b6eae;
} 