:root {
  --bg-dark: #2c2f33;
  --bg-medium: #36393f;
  --bg-light: #40444b;
  --text-light: #dcddde;
  --text-muted: #72767d;
  --accent-color: #7289da;
  --success-color: #43b581;
  --warning-color: #faa61a;
  --danger-color: #f04747;
  --chart-recovery: #43b581;
  --chart-strain: #faa61a;
  --chart-sleep: #7289da;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  margin: 0;
  padding: 0;
  background-color: var(--bg-dark);
  color: var(--text-light);
}

.app {
  display: flex;
  height: 100vh;
  background-color: #2f3136;
  color: #dcddde;
}

/* Chat Container Styles */
.chat-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #40444b;
}

.header {
  padding: 16px 24px;
  background-color: #36393f;
  border-bottom: 1px solid #40444b;
}

.header h1 {
  margin: 0;
  font-size: 1.5rem;
  color: #fff;
}

.chat-interface {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #36393f;
}

.message-list-container {
  flex: 1;
  overflow-y: auto;
  padding: 24px;
}

/* Whoop Data Styles */
.whoop-data {
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
  background-color: var(--bg-medium);
}

.whoop-summary {
  padding: 0;
}

.whoop-summary h2 {
  margin: 0 0 1rem 0;
  text-align: center;
}

.whoop-cards {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-right: 0.5rem;
}

.whoop-card {
  background-color: var(--bg-light);
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.whoop-card h3.date {
  margin: 0 0 1rem 0;
  color: var(--accent-color);
  font-size: 1.2rem;
  border-bottom: 1px solid var(--bg-medium);
  padding-bottom: 0.5rem;
}

.whoop-card h4 {
  margin: 1rem 0 0.5rem 0;
  color: var(--text-light);
  font-size: 1rem;
}

.metric {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  padding: 0.25rem 0;
}

.metric .label {
  color: var(--text-muted);
}

.metric .value {
  font-weight: bold;
  color: var(--text-light);
}

.sleep-metrics, .workouts {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid var(--bg-medium);
}

.workout {
  background-color: var(--bg-medium);
  border-radius: 4px;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.workout:last-child {
  margin-bottom: 0;
}

/* Message Styles */
.message {
  display: flex;
  gap: 1rem;
  align-items: flex-start;
  margin: 1rem 0;
  padding: 1rem;
  border-radius: 8px;
  max-width: 90%;
}

.message.user {
  background-color: var(--accent-color);
  margin-left: auto;
  color: white;
}

.message.ai {
  background-color: #2a2d31;
  margin-right: auto;
  color: var(--text-light);
}

.message.error {
  background-color: var(--danger-color);
  color: white;
  margin-right: auto;
}

.message-icon {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-medium);
  border-radius: 50%;
  padding: 0.5rem;
}

.message.user .message-icon {
  background-color: var(--bg-dark);
}

.message-content {
  flex: 1;
  min-width: 0;
}

/* Markdown Styles */
.message-content > * {
  margin: 0.5rem 0;
}

.message-content > *:first-child {
  margin-top: 0;
}

.message-content > *:last-child {
  margin-bottom: 0;
}

.message-content pre {
  background-color: var(--bg-medium);
  padding: 1rem;
  border-radius: 4px;
  overflow-x: auto;
}

.message-content code {
  background-color: var(--bg-medium);
  padding: 0.2rem 0.4rem;
  border-radius: 4px;
  font-family: 'Courier New', Courier, monospace;
}

.message-content pre code {
  padding: 0;
  background-color: transparent;
}

/* Follow-up Questions Styles */
.follow-up-questions {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid var(--bg-medium);
}

.follow-up-questions h4 {
  margin: 0 0 0.5rem 0;
  color: var(--text-muted);
  font-size: 0.9rem;
}

.follow-up-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.follow-up-button {
  background-color: var(--bg-medium);
  border: 1px solid var(--accent-color);
  border-radius: 16px;
  padding: 0.5rem 1rem;
  color: var(--text-light);
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.follow-up-button:hover {
  background-color: var(--accent-color);
  transform: translateY(-1px);
}

/* Suggested Prompts Styles */
.prompt-button {
  padding: 16px 24px;
  background-color: rgba(114, 137, 218, 0.1);
  border: 2px solid var(--accent-color);
  border-radius: 12px;
  color: var(--text-light);
  cursor: pointer;
  font-size: 0.95rem;
  line-height: 1.4;
  text-align: left;
  transition: all 0.2s ease;
  position: relative;
  overflow: hidden;
}

.prompt-button:hover {
  background-color: rgba(114, 137, 218, 0.2);
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(114, 137, 218, 0.2);
  border-color: #8ba0e5;
}

.prompt-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 8px rgba(114, 137, 218, 0.2);
}

/* Loading Animation */
.message.loading .message-content {
  display: flex;
  align-items: center;
  min-height: 24px;
}

/* Message Input Styles */
.message-input {
  padding: 1rem;
  background-color: var(--bg-light);
  border-top: 1px solid var(--bg-medium);
}

.input-container {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.message-input-field {
  flex: 1;
  padding: 0.75rem;
  border: 1px solid var(--bg-medium);
  border-radius: 20px;
  font-size: 1rem;
  background-color: var(--bg-medium);
  color: var(--text-light);
}

.message-input-field::placeholder {
  color: var(--text-muted);
}

.send-button {
  padding: 0.75rem;
  background-color: var(--accent-color);
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
}

.send-button:hover {
  background-color: #5b6eae;
  transform: scale(1.05);
}

.send-button:disabled {
  background-color: var(--text-muted);
  cursor: not-allowed;
}

/* Scrollbar Styles */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: var(--bg-medium);
}

::-webkit-scrollbar-thumb {
  background: var(--bg-light);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--text-muted);
}

/* Whoop Data Additional Styles */
.charts-section {
  margin-bottom: 2rem;
  background-color: var(--bg-light);
  padding: 1rem;
  border-radius: 8px;
}

.chart-container {
  margin-bottom: 2rem;
}

.chart-container h3 {
  color: var(--text-light);
  margin-bottom: 1rem;
  text-align: center;
}

.custom-chart-tooltip {
  background-color: var(--bg-medium);
  border: 1px solid var(--bg-light);
  padding: 0.5rem;
  border-radius: 4px;
}

.metrics-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1rem;
  margin-bottom: 1rem;
}

.metric-box {
  background-color: var(--bg-medium);
  padding: 1rem;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.metric-box.recovery { border-left: 3px solid var(--success-color); }
.metric-box.sleep { border-left: 3px solid var(--accent-color); }
.metric-box.strain { border-left: 3px solid var(--warning-color); }

.workout {
  background-color: var(--bg-medium);
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;
}

.workout-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid var(--bg-light);
}

.workout-header .sport {
  font-weight: bold;
  color: var(--accent-color);
}

.workout-metrics {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 1rem;
}

/* Add these styles to your existing index.css */

.weekly-overview {
  background-color: var(--bg-light);
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 2rem;
}

.weekly-overview h3 {
  margin: 0 0 1rem 0;
  text-align: center;
  color: var(--text-light);
}

.metric-box.overview {
  background-color: var(--bg-medium);
  padding: 1rem;
  border-radius: 8px;
  text-align: center;
}

.metric-box .sub-value {
  font-size: 0.8rem;
  color: var(--text-muted);
  margin-top: 0.25rem;
}

.zone-chart-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
}

.zone-legend {
  flex: 1;
  padding-left: 2rem;
}

.zone-legend-item {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.zone-color {
  width: 12px;
  height: 12px;
  border-radius: 2px;
  margin-right: 0.5rem;
}

.zone-name {
  flex: 1;
  color: var(--text-muted);
}

.zone-value {
  color: var(--text-light);
}

.workout-zones {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid var(--bg-light);
}

.workout-zones h5 {
  margin: 0 0 0.5rem 0;
  color: var(--text-muted);
  font-size: 0.9rem;
}

.zone-bars {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.zone-bar-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.8rem;
}

.zone-bar {
  height: 8px;
  border-radius: 4px;
  transition: width 0.3s ease;
}

.zone-label {
  min-width: 100px;
  color: var(--text-muted);
}

.zone-time {
  color: var(--text-light);
  min-width: 50px;
  text-align: right;
}

.week-navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.nav-button {
  background-color: #4a4a4a;
  border: none;
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.2s;
}

.nav-button:hover {
  background-color: #666;
}

.nav-button:disabled {
  background-color: #333;
  cursor: not-allowed;
}

/* Add these styles to your existing CSS */
.week-info {
  text-align: center;
}

.date-range {
  color: var(--text-muted);
  font-size: 0.9rem;
  margin-top: 0.25rem;
}

.week-navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;
  padding: 0 1rem;
}

.nav-button {
  background-color: var(--bg-light);
  border: none;
  color: var(--text-light);
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
  transition: all 0.2s;
}

.nav-button:hover {
  background-color: var(--accent-color);
}

.nav-button:disabled {
  background-color: var(--bg-medium);
  cursor: not-allowed;
  opacity: 0.5;
}

/* Add these styles to your existing CSS file */

.welcome-container {
  max-width: 900px;
  margin: 40px auto;
  text-align: center;
}

.welcome-message {
  font-size: 1.2rem;
  color: #fff;
  margin-bottom: 32px;
  line-height: 1.6;
  padding: 24px;
  background-color: rgba(114, 137, 218, 0.1);
  border: 1px solid var(--accent-color);
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.suggested-prompts {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 16px;
  margin-top: 24px;
}

.prompt-button {
  padding: 16px 24px;
  background-color: rgba(114, 137, 218, 0.1);
  border: 2px solid var(--accent-color);
  border-radius: 12px;
  color: var(--text-light);
  cursor: pointer;
  font-size: 0.95rem;
  line-height: 1.4;
  text-align: left;
  transition: all 0.2s ease;
  position: relative;
  overflow: hidden;
}

.prompt-button:hover {
  background-color: rgba(114, 137, 218, 0.2);
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(114, 137, 218, 0.2);
  border-color: #8ba0e5;
}

.prompt-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 8px rgba(114, 137, 218, 0.2);
}

/* Message Styles */
.message {
  max-width: 85%;
  margin: 16px 0;
  padding: 16px;
  border-radius: 8px;
  line-height: 1.5;
}

.message.user {
  background-color: #40444b;
  margin-left: auto;
  color: #fff;
}

.message.ai {
  background-color: #2f3136;
  margin-right: auto;
  color: #dcddde;
}

.message.error {
  background-color: #f04747;
  color: #fff;
  margin-right: auto;
}

/* Message Input Styles */
.message-input-container {
  padding: 24px;
  background-color: #40444b;
  border-top: 1px solid #4f545c;
}

.message-input-form {
  display: flex;
  gap: 16px;
}

.message-input {
  flex: 1;
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid #4f545c;
  background-color: #36393f;
  color: #dcddde;
  font-size: 1rem;
  transition: border-color 0.2s ease;
}

.message-input:focus {
  outline: none;
  border-color: #7289da;
}

.send-button {
  padding: 12px 24px;
  background-color: #7289da;
  border: none;
  border-radius: 8px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.send-button:hover {
  background-color: #677bc4;
}

.send-button:disabled {
  background-color: #4f545c;
  cursor: not-allowed;
}

/* Loading Animation */
.loading-dots {
  display: flex;
  gap: 4px;
  padding: 16px;
  align-items: center;
}

.dot {
  width: 8px;
  height: 8px;
  background-color: #7289da;
  border-radius: 50%;
  animation: bounce 1.4s infinite ease-in-out;
}

.dot:nth-child(1) { animation-delay: -0.32s; }
.dot:nth-child(2) { animation-delay: -0.16s; }

@keyframes bounce {
  0%, 80%, 100% { transform: scale(0); }
  40% { transform: scale(1); }
}

/* Scrollbar Styles */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #2f3136;
}

::-webkit-scrollbar-thumb {
  background: #202225;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #40444b;
}

/* Add these styles to your existing loading animation styles */
.thinking-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.thinking-text {
  color: var(--text-muted);
  font-size: 0.9rem;
}

.loading-dots {
  display: flex;
  gap: 4px;
  align-items: center;
}

.dot {
  width: 6px;
  height: 6px;
  background-color: var(--text-muted);
  border-radius: 50%;
  animation: bounce 1.4s infinite ease-in-out;
}

/* Sidebar Styles */
.sidebar {
  width: 260px;
  background-color: var(--bg-dark);
  border-right: 1px solid var(--bg-light);
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
}

.sidebar.closed {
  width: 0;
  overflow: hidden;
}

.new-chat-button {
  margin: 8px;
  padding: 12px;
  background-color: var(--accent-color);
  border: none;
  border-radius: 6px;
  color: white;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: background-color 0.2s;
}

.new-chat-button:hover {
  background-color: #677bc4;
}

.chat-history {
  flex: 1;
  overflow-y: auto;
  padding: 8px;
}

.chat-history-item {
  display: flex;
  align-items: center;
  position: relative;
  padding: 8px;
  margin: 4px 0;
  border-radius: 4px;
  background-color: transparent;
  transition: background-color 0.2s ease;
  width: 100%;
  box-sizing: border-box;
}

.chat-history-item:hover {
  background-color: var(--bg-medium);
}

.chat-history-item.active {
  background-color: var(--bg-light);
}

.chat-button {
  display: flex;
  align-items: center;
  gap: 8px;
  background: none;
  border: none;
  color: inherit;
  padding: 8px;
  flex: 1;
  min-width: 0;
  text-align: left;
  cursor: pointer;
  font-size: 0.9rem;
}

.chat-icon {
  flex-shrink: 0;
  font-size: 0.9rem;
  color: var(--text-muted);
}

.chat-title {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 8px;
  max-width: calc(100% - 50px);
}

.delete-chat-button {
  opacity: 0;
  visibility: hidden;
  background: none;
  border: none;
  color: var(--danger-color);
  padding: 4px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-left: auto;
}

.chat-history-item:hover .delete-chat-button {
  opacity: 1;
  visibility: visible;
}

.delete-chat-button:hover {
  color: var(--text-light);
  background-color: var(--danger-color);
  border-radius: 4px;
}

/* Main Content Styles */
.main-content {
  flex: 1;
  display: flex;
  transition: margin-left 0.3s ease;
}

.main-content.sidebar-closed {
  margin-left: 0;
}

/* Toggle Sidebar Button */
.toggle-sidebar {
  background: none;
  border: none;
  color: var(--text-light);
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0 12px;
  display: flex;
  align-items: center;
}

.toggle-sidebar:hover {
  color: var(--accent-color);
}

/* Update header styles */
.header {
  display: flex;
  align-items: center;
  gap: 16px;
}

/* Auth Styles */
.auth-buttons {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.auth-button {
  width: 100%;
  padding: 12px;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: all 0.2s ease;
}

.auth-button.google {
  background-color: white;
  color: #333;
}

.auth-button.google:hover {
  background-color: #f5f5f5;
  transform: translateY(-1px);
}

.google-icon {
  width: 18px;
  height: 18px;
}

.user-profile {
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  border-bottom: 1px solid var(--bg-light);
}

.profile-image {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.user-name {
  color: var(--text-light);
  font-weight: 500;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sign-out-button {
  margin: 8px;
  padding: 12px;
  background-color: var(--bg-light);
  border: none;
  border-radius: 6px;
  color: var(--text-light);
  cursor: pointer;
  transition: background-color 0.2s;
}

.sign-out-button:hover {
  background-color: var(--danger-color);
}

/* Auth Form Styles */
.auth-form {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.auth-form h2 {
  color: var(--text-light);
  text-align: center;
  margin: 0 0 16px 0;
}

.auth-form form {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.auth-form input {
  padding: 12px;
  border-radius: 4px;
  border: 1px solid var(--bg-light);
  background-color: var(--bg-medium);
  color: var(--text-light);
  font-size: 1rem;
}

.auth-form input:focus {
  outline: none;
  border-color: var(--accent-color);
}

.auth-submit {
  padding: 12px;
  background-color: var(--accent-color);
  border: none;
  border-radius: 4px;
  color: white;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s;
}

.auth-submit:hover {
  background-color: #677bc4;
}

.auth-switch {
  background: none;
  border: none;
  color: var(--text-muted);
  cursor: pointer;
  font-size: 0.9rem;
  padding: 8px;
  text-align: center;
}

.auth-switch:hover {
  color: var(--text-light);
}

.auth-error {
  color: var(--danger-color);
  font-size: 0.9rem;
  text-align: center;
  padding: 8px;
  background-color: rgba(240, 71, 71, 0.1);
  border-radius: 4px;
}

/* Update user profile styles for email display */
.user-profile {
  padding: 16px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--bg-light);
}

.user-name {
  color: var(--text-light);
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.chat-history-item {
  display: flex;
  align-items: center;
  position: relative;
  padding: 8px;
  margin: 4px 0;
  border-radius: 4px;
}

.chat-history-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.chat-button {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  color: inherit;
  padding: 0;
  flex-grow: 1;
  text-align: left;
  cursor: pointer;
}

.delete-chat-button {
  opacity: 0;
  background: none;
  border: none;
  color: #ff4444;
  padding: 4px;
  cursor: pointer;
  transition: opacity 0.2s ease;
}

.chat-history-item:hover .delete-chat-button {
  opacity: 1;
}

.delete-chat-button:hover {
  color: #ff6666;
}

.whoop-credentials-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  background-color: #f5f5f5;
}

.whoop-credentials-form {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}

.whoop-credentials-form h2 {
  margin-bottom: 1rem;
  color: #333;
}

.whoop-credentials-form input {
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.whoop-credentials-form button {
  width: 100%;
  padding: 0.75rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.whoop-credentials-form button:hover {
  background-color: #0056b3;
}

.auth-error {
  color: #dc3545;
  margin-bottom: 1rem;
  font-size: 0.875rem;
}

.unauthorized-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  background-color: var(--bg-dark);
}

.unauthorized-message {
  background-color: var(--bg-medium);
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  text-align: center;
}

.unauthorized-message h2 {
  color: var(--danger-color);
  margin-bottom: 1rem;
}

.unauthorized-message p {
  color: var(--text-light);
  line-height: 1.5;
}

.unauthorized-message-chat {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 2rem;
  text-align: center;
  background-color: var(--bg-medium);
}

.unauthorized-message-chat h2 {
  color: var(--danger-color);
  margin-bottom: 1rem;
}

.unauthorized-message-chat p {
  color: var(--text-light);
  line-height: 1.5;
  max-width: 400px;
}

.login-prompt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 2rem;
  background-color: var(--bg-dark);
  text-align: center;
}

.login-prompt h2 {
  color: var(--text-light);
  margin-bottom: 1rem;
  font-size: 2rem;
}

.login-prompt p {
  color: var(--text-muted);
  margin-bottom: 2rem;
  max-width: 400px;
  line-height: 1.5;
}

.login-form {
  width: 100%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
}

.login-form input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid var(--bg-light);
  border-radius: 4px;
  background-color: var(--bg-medium);
  color: var(--text-light);
  font-size: 1rem;
}

.login-form input::placeholder {
  color: var(--text-muted);
}

.login-form input:focus {
  outline: none;
  border-color: var(--accent-color);
}

.auth-submit {
  width: 100%;
  padding: 0.75rem;
  background-color: var(--accent-color);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  transition: background-color 0.2s;
}

.auth-submit:hover {
  background-color: #5b6eae;
}

.auth-error {
  color: var(--danger-color);
  background-color: rgba(240, 71, 71, 0.1);
  padding: 0.75rem;
  border-radius: 4px;
  margin-bottom: 1rem;
  width: 100%;
  max-width: 300px;
}

/* Add styles for the switch button */
.auth-switch {
  background: none;
  border: none;
  color: var(--accent-color);
  cursor: pointer;
  font-size: 0.9rem;
  padding: 0.5rem;
  margin-top: 1rem;
  transition: color 0.2s;
}

.auth-switch:hover {
  color: var(--text-light);
  text-decoration: underline;
}