.profile-view {
  flex: 1;
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: var(--bg-medium);
  border-radius: 8px;
  position: relative;
  overflow-y: auto;
  height: calc(100vh - 4rem);
}

.profile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--bg-light);
}

.profile-header h2 {
  color: var(--text-light);
  margin: 0;
}

.profile-actions {
  display: flex;
  gap: 1rem;
}

.edit-button, .back-button {
  padding: 0.5rem 1rem;
  background-color: var(--accent-color);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.back-button {
  background-color: var(--bg-light);
}

.edit-button:hover {
  background-color: #5b6eae;
}

.back-button:hover {
  background-color: var(--bg-dark);
}

.profile-section {
  margin-bottom: 2rem;
  padding: 1.5rem;
  background-color: var(--bg-dark);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.profile-section h3 {
  color: var(--text-light);
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid var(--bg-light);
}

.profile-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1.5rem;
}

.profile-item {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.profile-item label {
  color: var(--text-muted);
  font-size: 0.9rem;
  font-weight: 500;
}

.profile-item span {
  color: var(--text-light);
  font-size: 1rem;
  padding: 0.5rem;
  background-color: var(--bg-medium);
  border-radius: 4px;
  min-height: 2.5rem;
  display: flex;
  align-items: center;
}

.profile-item.list span {
  flex-wrap: wrap;
  gap: 0.5rem;
  padding: 0.75rem;
}

.profile-item.list .tag {
  background-color: var(--bg-light);
  padding: 0.25rem 0.75rem;
  border-radius: 12px;
  font-size: 0.9rem;
  display: inline-block;
}

/* Scrollbar styles */
.profile-view::-webkit-scrollbar {
  width: 8px;
}

.profile-view::-webkit-scrollbar-track {
  background: var(--bg-dark);
}

.profile-view::-webkit-scrollbar-thumb {
  background: var(--bg-light);
  border-radius: 4px;
}

.profile-view::-webkit-scrollbar-thumb:hover {
  background: var(--text-muted);
} 